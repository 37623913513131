// 引入拦截器配置
import {requestInterceptors,responseInterceptors} from './interceptors'
// 引入luch-request
import { http } from 'uview-plus'
import type { HttpRequestConfig } from "@/types/http";
//  初始化请求配置
const initRequest=()=>{
	http.setConfig((defaultConfig:HttpRequestConfig) => {
		defaultConfig.baseURL = import.meta.env.VITE_API_BASE_URL
		return defaultConfig
	})
	requestInterceptors()
	responseInterceptors()
}
export {
	initRequest
}