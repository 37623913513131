

import { createSSRApp } from "vue";
import App from "./App.vue";
import pinia from "./stores";
import uviewPlus from "uview-plus"
import {initRequest} from "@/utils/request"
export function createApp() {
  const app = createSSRApp(App);
  app.use(pinia)
  initRequest(app)
  app.use(uviewPlus)
  return {
    app,
  };
}
