<script setup lang="ts">
import { onLaunch, onShow, onHide } from "@dcloudio/uni-app";
onLaunch(() => {
  console.log("App Launch");
});
onShow(() => {
  console.log("App Show");
});
onHide(() => {
  console.log("App Hide");
});
</script>
<style lang="scss">
@import "uview-plus/index.scss";
@import "tailwindcss/base";
@import "tailwindcss/utilities";
@import "tailwindcss/components";
@font-face {
  /* 阿里妈妈数黑体 */
  font-family: "AlimamaShuHeiTi";
  src: url("https://img.triproaming.cn/triproaming/AlimamaShuHeiTi-Bold.otf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  /* 思源黑体 */
  font-family: "SourceHanSans";
  src: url("https://img.triproaming.cn/triproaming/Source_Han_Sans_SC_Regular.otf");
  font-weight: normal;
  font-style: normal;
}
html,body{
  padding: 0;
  margin: 0;
}
body {
  line-height: 1.6;
  font-family: SourceHanSans, Inter, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
