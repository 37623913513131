import { http, toast } from "uview-plus";
import { storeToRefs } from "pinia";
import { useUserStore } from "@/stores";
import type { HttpRequestConfig, HttpResponse, AnyObject } from "@/types/http";

// import store from '@/stores'
const requestInterceptors = () => {
  /**
   * 请求拦截
   * @param {Object} http
   */
  http.interceptors.request.use(
    (config: HttpRequestConfig) => {
      // 可使用async await 做异步操作
      // 初始化请求拦截器时，会执行此方法，此时data为undefined，赋予默认{}
      config.data = config.data || {};
      if (config?.custom?.auth) {
        const authStore = useUserStore();
        const { authToken } = storeToRefs(authStore);
        if (authToken) {
          const token = "Bearer " + authToken.value || "";
          (config.header as AnyObject).Authorization = token;
        }
      }
      return config;
    },
    (
      config:HttpRequestConfig // 可使用async await 做异步操作
    ) => Promise.reject(config)
  );
};
const responseInterceptors = ( ) => {
  /**
   * 响应拦截
   * @param {Object} http
   */
  http.interceptors.response.use(
    (response:HttpResponse) => {
      /* 对响应成功做点什么 可使用async await 做异步操作*/
      const data = response.data;
      // console.log('http', data)
      // 自定义参数
      const custom = response.config?.custom;
      if (data.status !== 200) {
        // 服务端返回的状态码不等于200，则reject()
        // 如果没有显式定义custom的toast参数为false的话，默认对报错进行toast弹出提示
        if (custom?.toast !== false) {
          toast(data.msg);
        }
        // 如果需要catch返回，则进行reject
        if (custom?.catch) {
          return Promise.reject(data);
        } else {
          // 否则返回一个pending中的promise
          return new Promise(() => {});
        }
      }
      return data || {};
    },
    (response:HttpResponse) => {
      /*  对响应错误做点什么 （statusCode !== 200）*/
      return Promise.reject(response);
    }
  );
};

export { requestInterceptors, responseInterceptors };
