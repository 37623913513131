import { defineStore } from "pinia";
import { ref } from "vue";

export const useUserStore = defineStore(
  "user",
  () => {
    const authToken = ref('')

    const setAuthToken = (value:string)=>authToken.value = value;

    const $reset = ()=> {
      authToken.value = ''
    };

    return {
      authToken,
      setAuthToken,
      $reset,
    };
  },
  // 配置持久化
  {
    // PC 端使用 localStorage
    // persist: true,
    // uni-app 的持久化存储
    persist: {
      storage: {
        setItem(key, value) {
          return uni.setStorageSync(key, value);
        },
        getItem(key) {
          return uni.getStorageSync(key);
        },
      },
    },
  }
);
